<template>
  <ConsolidationUnionCreateLabelAdmin
      :CUL="CUL"
      :warehousesData="warehousesData"
      @saveConsolidationUnionLabel="saveConsolidationUnionLabel"
  />
</template>

<script>
  import ConsolidationUnionCreateLabelAdmin from "./ConsolidationUnionCreateLabelAdmin/ConsolidationUnionCreateLabelAdmin";
  import {ConsolidationUnionLabel} from "../../models/ConsolidationUnionLabel";
  import {consolidationUnionLabelMixin} from "../../../../../mixins/consolidationUnionMixins/consolidationUnionLabelMixin";
  // import {
  //   CONSOLIDATION_UNION_WAREHOUSES_ARRAY
  // } from "../../../../../staticData/staticVariables";

  export default {
    name: "ConsolidationUnionCreateLabel",

    components: {
      ConsolidationUnionCreateLabelAdmin,
    },

    mixins: [
      consolidationUnionLabelMixin
    ],

    data() {
      return {
        CUL: new ConsolidationUnionLabel(),
        warehousesData: [],
      }
    },

    created() {
      this.initConsolidationUnionLabelCreate()
    },

    methods: {
      initConsolidationUnionLabelCreate() {

        const query = new this.Query();
        let url = '?';
        let myQuery = query
            .for('posts')

        myQuery.params({ consolidation_union_id: this.$route.params.id })
        this.CUL.setConsolidationUnionId(this.$route.params.id)

        url = url + myQuery.limit(100).page(1).url().split('?')[1]

        this.$store.dispatch('getConsolidationUnionCreateLabel', url).then(response => {
          let respData = this.getRespData(response)
          this.warehousesData = respData.warehouses
          // this.warehousesData = respData.warehouses.filter(item => {
          //   return CONSOLIDATION_UNION_WAREHOUSES_ARRAY.find(findItem => {
          //     return findItem.id === item.id
          //   })
          // })

          this.CUL.setWarehouse(respData['consolidation-union-label']['warehouse'])
          this.CUL.setTotalPrice(respData['consolidation-union-label']['declare_sum'])
        }).catch(error => this.createErrorLog(error))
      },

    }
  }
</script>

<style scoped>

</style>