<template>
  <div class="detail-page reverse">
    <div class="detail-page__left">
      <CardLeftBlock
          class="clear-992"
          :backgroundImage="'product-admin'"
      >
        <template slot="body">
          <div class="card-detail-left__date">
            {{ new Date() | moment("DD MMM, YYYY") }}
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'consolidationUnion_backTo',
                  ])"></div>
                <router-link :to="$store.getters.GET_PATHS.reportsConsolidation" class="detail-page__head-back">
                  <LinkBack
                      :value="$t('consolidationUnion_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'consolidationUnion_createConsUnionLabel',
                  ])"></div>
              {{$t('consolidationUnion_createConsUnionLabel.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-2"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'consolidationUnion_createConsUnionLabelText',
                  ])"></div>
              {{$t('consolidationUnion_createConsUnionLabelText.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <LabelSendDetails
              :CUL="CUL"
              :warehousesData="warehousesData"
            />

            <LabelDocumentsBlock
                :CUL="CUL"
            />
          </div>
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'common_cancel',
                  ])"></div>
              <router-link :to="$store.getters.GET_PATHS.reportsConsolidation"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'common_create',
                  ])"></div>
              <MainButton
                  class="order-create__footer-btn-i"
                  :value="$t('common_create.localization_value.value')"
                  @click.native="$emit('saveConsolidationUnionLabel')"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>
    </div>

  </div>
</template>

<script>
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import LabelSendDetails from "../../chunks/LabelSendDetails";
  import LabelDocumentsBlock from "../../chunks/LabelDocumentsBlock";

  export default {
    name: "ConsolidationUnionCreateLabelAdmin",
    components: {
      LabelDocumentsBlock,
      LabelSendDetails,
      MainButton,
      CardLeftBlock,
      CardRightBlock,
      LinkBack,
    },

    mixins: [mixinDetictingMobile],

    props: {
      CUL: Object,
      warehousesData: Array,
    },

    data(){
      return{
        options: [{}],
        valueQuantity: 100,
        comment: 'Please be careful with the vase inside! ',
        input01: '',
        input02: '',
        input03: '',

        optionsMulti: [
          '#49357: $100.4',
          '#49357: $10.4',
          '#49357: $59.2'
        ],
      }
    },
  }
</script>

<style scoped>
  .checkbox-scoped{
    margin-right: -5px;
  }

  td:nth-child(2){
    font-size: 12px;
    line-height: 14px;
  }
</style>
